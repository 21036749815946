import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import UtpalHeadshot from "../images/utpal-headshot.png"
import RamanaHeadshot from "../images/ramana-headshot.png"
import { Box, Grid, Typography, Container } from "@material-ui/core"

class AboutUs extends React.Component {
  render() {
    return (
      <Layout>
        <div className="LinkedPageBackgroundColor">
          <SEO title="About Us"></SEO>
          <Container maxWidth="md">
            <h1 align="center">About Us</h1>
            <div className="TopQuote" align="center">
              We enable the 99% to build wealth using sound investment
              strategies
            </div>
            <div className="PageContent">
              The ultra-rich continue to reap high returns on their investments
              while everybody else is saddled with below par returns. One reason
              for this discrepancy is the disparity in access to sophisticated
              investment strategies. Our founder, having managed such strategies
              at hedge funds, was frustrated at how friends and family were
              missing out on getting access to expert strategies.
            </div>
            <div className="PageContent">
              As a team of product builders and investment experts, we will
              bring Wall Street's alternative investment strategies to Main
              Street. We hope to earn your trust as a partner for the long haul
              on your wealth-building journey.
            </div>

            {/* <div className="TeamBios">
              <div className="TeamBio">
                <p align="center">
                  <img
                    align="center"
                    width="200px"
                    src={UtpalHeadshot}
                    alt="Utpal Diwan head shot"
                  ></img>
                </p>
                <p align="center">
                  Utpal Diwan
                  <br />
                  Co-CEO and Founder
                </p>
                <p />
                Utpal founded 99rises to democratize access to investment
                strategies available only to the wealthy. He has managed $1B+
                long-short equity portfolios and has worked at multi-billion
                dollar funds such as Citadel and D.E. Shaw. He was founder and
                CEO of Mobius Capital Management where he developed an AI/RNN
                based commodity price predictor. Utpal has an Engineering MS
                from Stanford and an MBA from Columbia.
              </div>

              <div className="TeamBio">
                <p align="center">
                  <img
                    align="center"
                    width="200px"
                    src={RamanaHeadshot}
                    alt="Ramana Rao head shot"
                  ></img>
                </p>
                <p align="center">
                  Ramana Rao <br />
                  Co-CEO and Founder
                </p>
                <p />
                Ramana is a serial startup founder and bigco exec. As such, he
                has raised $120M+, built many products, and seen acquisitions to
                SAP/Business Objects, the Washington Post, and Adobe. He started
                out as an AI/CS & HCI researcher and inventor with many
                highly-cited papers and widely-licensed patents. He has BS and
                MS degrees in Computer Science from MIT.
              </div>
            </div> */}
          </Container>
        </div>
      </Layout>
    )
  }
}
export default AboutUs
